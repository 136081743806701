import React, { useRef, useState } from 'react';

// hooks
import { useScrollPosition } from 'hooks/useScrollPosition';

// configs
import { LANG } from 'constants/lang';

// theme
import logo from 'assets/trakan-white.svg';
import styles from './Header.module.scss';


export const Header = () => {
  const [isHeaderShrunk, setIsHeaderShrunk] = useState();
  const headerShrunk = useRef();

  const setHeaderShrunkState = (isShrunk) => {
    headerShrunk.current = isShrunk;
    setIsHeaderShrunk(isShrunk);
  };

  useScrollPosition(({ curPos }) => {
    if (curPos) {
      if (curPos.y < 0 && !headerShrunk.current) {
        setHeaderShrunkState(true);
      } else if (curPos.y >= 0 && headerShrunk.current) {
        setHeaderShrunkState(false);
      }
    }
  });

  return  (
    <header className={isHeaderShrunk && styles.shrink}>
      <div className={styles.headerWrap}>
        <img src={logo} className={styles.AppLogo} alt={LANG.header.title}/>
      </div>
    </header>
  );
};
