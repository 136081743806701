import React from 'react';

// constants
import { LANG } from 'constants/lang';

// theme
import styles from './Footer.module.scss';

export const Footer = () => (
  <footer>
    <p className={`${styles.small} ${styles.createdBy}`}>
      {`${LANG.footer.disclaimer}-${new Date().getFullYear()}`}
    </p>
  </footer>
);
