import React from 'react';

// constants
import { LANG } from 'constants/lang';

// theme
import styles from './Bio.module.scss';

const Bio = () => {
  const {title, text, aria} = LANG.bio;

  const Text = (
      text.map((item, i) => (
          <p key={i}>{item}</p>
      ))
  );

  return (
      <article id="bio-text" aria-label={aria}>
        {/* hidden from screen readers, but available to screen readers */}
        <h1 className={styles.visuallyHidden}>
          {`Trakan ${title}`}
        </h1>
        {/* visible but hidden from screen readers */}
        <p className={`${styles.h6} ${styles.bio}`}
           aria-hidden="true"
        >
          {title}
        </p>
        {text.length > 0 && Text}
      </article>
  );
};

export default Bio;
