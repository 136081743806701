import { TYPES } from './actionTypes';
import {
  LOADING,
  SONGS,
  RECORDS,
} from './constants';

/**
 * REDUCER: APP
 */

// initial state for the app store entity
const initialState = {
    [LOADING]: false,
    [SONGS]: [],
    [RECORDS]: [],
  };

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.SET_LOADING:
      return {
        ...state,
        [LOADING]: action.isLoading
      };

    case TYPES.FETCH_SONGS:
      return {
        ...state,
        [LOADING]: true
      };

    case TYPES.SET_SONGS:
      return {
        ...state,
        [SONGS]: action.payload,
        [LOADING]: false
      };

    case TYPES.FETCH_RECORDS:
      return {
        ...state,
        [LOADING]: true
      };

    case TYPES.SET_RECORDS:
      return {
        ...state,
        [RECORDS]: action.payload,
        [LOADING]: false
      };

    default:
      return state;
  }
};
