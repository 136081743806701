import React from 'react';
import PropTypes from 'prop-types';

// LIGHT BG
import amazon from 'assets/icons/light/amazon.svg';
import bandcamp from 'assets/icons/light/bandcamp.svg';
import facebook from 'assets/icons/light/facebook.svg';
import itunes from 'assets/icons/light/itunes.svg';
import soundcloud from 'assets/icons/light/soundcloud.svg';
import twitter from 'assets/icons/light/twitter.svg';
import youtube from 'assets/icons/light/youtube.svg';

// DARK BG
import amazonDark from 'assets/icons/dark/amazon.svg';
import bandcampDark from 'assets/icons/dark/bandcamp.svg';
import facebookDark from 'assets/icons/dark/facebook.svg';
import itunesDark from 'assets/icons/dark/itunes.svg';
import soundcloudDark from 'assets/icons/dark/soundcloud.svg';
import twitterDark from 'assets/icons/dark/twitter.svg';
import youtubeDark from 'assets/icons/dark/youtube.svg';

// constants
import { SOCIAL_BRANDS, SOCIAL } from 'constants/social';
import { COLORS, SIZES } from 'constants/theme';
import { LANG } from 'constants/lang';

// theme
import styles from './SocialIcon.module.scss';

const SocialIcon = ({
    brand, // 'itunes'
    color, // 'dark/light'
    size, // 'small/large'
  }) => {

  const icon = () => {
    switch(brand) {
      case SOCIAL_BRANDS.AMAZON:
        return color === COLORS.DARK ? amazonDark : amazon;
      case SOCIAL_BRANDS.BANDCAMP:
        return color === COLORS.DARK ? bandcampDark : bandcamp;
      case SOCIAL_BRANDS.FACEBOOK:
        return color === COLORS.DARK ? facebookDark : facebook;
      case SOCIAL_BRANDS.ITUNES:
        return color === COLORS.DARK ? itunesDark : itunes;
      case SOCIAL_BRANDS.SOUNDCLOUD:
        return color === COLORS.DARK ? soundcloudDark : soundcloud;
      case SOCIAL_BRANDS.TWITTER:
        return color === COLORS.DARK ? twitterDark : twitter;
      case SOCIAL_BRANDS.YOUTUBE:
        return color === COLORS.DARK ? youtubeDark : youtube;
      default:
        return null;
    }
  };

  const { at } = LANG.dictionary;

  // 'Visit', or 'Shop' Trakan at [brand]
  const userAction = LANG.social.action[SOCIAL[brand].type];
  const title = brand && `${userAction} Trakan ${at} ${SOCIAL[brand].name}`;

  return (
    <div
      id={`icon-${brand}-${color}-${size}`}
      className={`${styles.socialIcon} ${styles[color]} ${styles[size]}`}
      aria-label={title}
    >
      <a href={SOCIAL[brand].link}
         title={title}
         target="_blank"
         rel="noopener noreferrer"
      >
        <img
            src={icon()}
            alt={brand}
        />
      </a>
    </div>
  );
};

SocialIcon.propTypes = {
  brand: PropTypes.string.isRequired,
  color: PropTypes.string,
  size: PropTypes.string,
};

SocialIcon.defaultProps = {
  color: COLORS.LIGHT,
  size: SIZES.SMALL,
};

export default SocialIcon;
