export const SOCIAL_BRANDS = {
  AMAZON: 'AMAZON',
  BANDCAMP: 'BANDCAMP',
  FACEBOOK: 'FACEBOOK',
  TWITTER: 'TWITTER',
  SOUNDCLOUD: 'SOUNDCLOUD',
  YOUTUBE: 'YOUTUBE',
  ITUNES: 'ITUNES',
};

export const SOCIAL_TYPES = {
  STORE: 'STORE',
  APP: 'APP',
};

export const SOCIAL = {
  BANDCAMP: {
    type: SOCIAL_TYPES.STORE,
    brand: SOCIAL_BRANDS.BANDCAMP,
    name: 'Bandcamp',
    link: 'https://trakanmusic.bandcamp.com/',
  },
  ITUNES: {
    type: SOCIAL_TYPES.STORE,
    brand: SOCIAL_BRANDS.ITUNES,
    name: 'ITunes',
    link: 'https://itunes.apple.com/us/artist/trakan/id267061074',
  },
  AMAZON: {
    type: SOCIAL_TYPES.STORE,
    brand: SOCIAL_BRANDS.AMAZON,
    name: 'Amazon',
    link: 'https://www.amazon.com/s?k=Trakan&i=digital-music',
  },
  FACEBOOK: {
    type: SOCIAL_TYPES.APP,
    brand: SOCIAL_BRANDS.FACEBOOK,
    name: 'Facebook',
    link: 'https://www.facebook.com/trakanmusic/',
  } ,
  SOUNDCLOUD: {
    type: SOCIAL_TYPES.APP,
    brand: SOCIAL_BRANDS.SOUNDCLOUD,
    name: 'SoundCloud',
    link: 'https://soundcloud.com/trakan',
  },
  TWITTER: {
    type: SOCIAL_TYPES.APP,
    brand: SOCIAL_BRANDS.TWITTER,
    name: 'Twitter',
    link: 'https://twitter.com/trakanmusic',
  },
};
