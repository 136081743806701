import React from 'react';
import { Provider } from 'react-redux';

/**
 * Reusable State Provider - can be used in App and also in tests
 * @param children - child components
 * @param store - redux store
 */
// eslint-disable-next-line react/prop-types
const ProviderWrapper = ({ children, store }) => (
  <Provider store={store}>
    {children}
  </Provider>
);

export default ProviderWrapper;
