import React from 'react';
import ReactDOM from 'react-dom';

// state management
import store from 'store';
import Provider from 'Provider';

// service worker
import * as serviceWorker from 'serviceWorker';

// components
import App from 'components/App/App';

// theme
/* eslint-disable no-unused-vars */
import styles from './index.module.scss';

ReactDOM.render(
  <Provider store={store}>
    <div className="AppWrapper">
      <App/>
    </div>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
