import React from 'react';

// Router
import { Router } from 'router/Router';

// theme
/* eslint-disable no-unused-vars */
import styles from './Main.module.scss';

export const Main = () => (
  <main>
    <Router/>
  </main>
);