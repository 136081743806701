// todo - internationalization

import { SOCIAL_TYPES } from 'constants/social';

export const LANG = {
  app: {
    title: `Trakan`
  },

  header: {
    title: `Trakan`,
  },

  footer: {
    disclaimer: 'All content copyright 2007',
  },

  bio: {
    title: `... is a musician & songwriter from Chicago`,
    text: [],
    aria: 'About Trakan',
  },

  contact: {
    text: [
      `Drop Trakan a line anytime at:`,
    ],
    link: `trakan@trakan.com`,
    glassesAlt: 'Contact Trakan',
    aria: 'Contact Details',
  },

  news: {
    text: [
      `Not a lot of news at the moment. Keeping busy writing new songs here in [YEAR] `
      + `and looking forward to sharing them with you all real soon!  So until we meet again...`,
    ],
    aria: 'News',
  },

  record: {
    aria: 'Record Store',
  },

  social: {
    action: {
      [SOCIAL_TYPES.APP]: 'Visit',
      [SOCIAL_TYPES.STORE]: 'Shop',
    },
    aria: 'Social Media'
  },

  songs: {
    aria: 'Listen to Songs',
  },

  // LIBRARY

  dictionary: {
    at: 'at',
    buy: 'buy',
    listen: 'listen',
    on: 'on',
    to: 'to',
  },

};
