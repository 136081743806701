import { combineReducers } from 'redux';

// app Store
import { APP } from 'constants/redux';
import { reducer as appReducer } from './app/reducer';


export default combineReducers({
  [APP]: appReducer,
});
