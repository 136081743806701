import React from 'react';
import PropTypes from 'prop-types';

// constants
import { LANG } from 'constants/lang';

// theme
import styles from './Record.module.scss';

const Record = ({ title, link, img, service }) => {
  const { buy, on } = LANG.dictionary;

  return (
      <div className={styles.record}>
        <a href={link}
           target="_blank"
           rel="noopener noreferrer"
        >
          <div className={styles.recordWrap}>
            <figure className={styles.glasses}>
              {/* eslint-disable-next-line global-require,import/no-dynamic-require */}
              <img src={require(`../../../../assets/${img}`)}
                   alt={`${buy} ${title} ${on} ${service}`}
                   title={title}
              />
            </figure>
            <p className={`${styles.h5} ${styles.title}`}>
              {title}
            </p>
          </div>
        </a>
      </div>
  );
};

Record.propTypes = {
  title: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  img: PropTypes.string.isRequired,
  service: PropTypes.string.isRequired,
};

export default Record;
