import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from 'store/app/actions';

// components
import SoundcloudSong from 'components/_library/_atoms/SoundcloudSong/SoundcloudSong';

// constants
import { APP } from 'constants/redux';
import { SONGS } from 'store/app/constants';
import { SOUNDCLOUD } from 'constants/soundcloud';
import { LANG } from 'constants/lang';

// theme
import styles from './Songs.module.scss';

const Songs = () => {
  const dispatch = useDispatch();
  const songs = useSelector(state => state[APP][SONGS]);

  // on component mount
  useEffect(() => {
    // get the test and store them in redux
    dispatch(actions.fetchSongs());

    // on component unmount
    return () => {};
  }, [dispatch]);


  const SongsList = (
    songs.map(({ type, active, data }) => {
      let song;

      if (active) {
        const config = {...data};

        const {
          artist,
          artistTitle,
          hideRelated,
          showComments,
          showUser,
          showReposts,
          showTeaser
        } = SOUNDCLOUD;

        config.meta.soundcloudArtist = artist;
        config.meta.soundcloudArtistTitle = artistTitle;
        config.hideRelated = hideRelated;
        config.showComments = showComments;
        config.showUser = showUser;
        config.showReposts = showReposts;
        config.showTeaser = showTeaser;

        switch (type) {
          case 'soundcloud':
            song = <SoundcloudSong key={data.id} {...config} />;
            break;

          default:
            // do nothing
        }
      }
      return song;
    })
  );

  return (
    <article id="songs" className={styles.songs} aria-label={LANG.songs.aria}>
      {songs && SongsList}
    </article>
  );
};

export default Songs;
