import React from 'react';

// constants
import { LANG } from 'constants/lang';

// theme
import glasses from 'assets/trakan-glasses.svg';
import styles from './Contact.module.scss';

const Contact = () => {
  const {text, link, glassesAlt, aria} = LANG.contact;

  const Text = (
    text.map((item, i) => (
        <span key={i}>{item}</span>
    ))
  );

  return (
    <article id="contact-details" className={styles.contact} aria-label={aria}>
      <div className={styles.contactWrap}>
        <div className={styles.contactHeader}>
          <a href={`mailto:${link}`}>
            <figure className={styles.glasses}>
              <img
                  src={glasses}
                  alt={glassesAlt}
              />
            </figure>
            <h4 className={`${styles.h6} ${styles.white}`}>{text.length > 0 && Text}</h4>
          </a>
        </div>
        <div className={styles.contactDetails}>
          <a href={`mailto:${link}`}>{link}</a>
        </div>
      </div>
    </article>
  );
};

export default Contact;
