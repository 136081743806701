import { TYPES } from './actionTypes';

/**
 * ACTIONS: APP STORE
 */
export const setIsLoading = (isLoading = true) => (
  {
    type: TYPES.SET_LOADING,
    isLoading
  }
);

export const setSongs = (payload) => {
  return {
    type: TYPES.SET_SONGS,
    payload
  };
};

export const fetchSongs = (payload) => {
  return {
    type: TYPES.FETCH_SONGS,
    payload
  };
};

export const setRecords = (payload) => {
  return {
    type: TYPES.SET_RECORDS,
    payload
  };
};

export const fetchRecords = (payload) => {
  return {
    type: TYPES.FETCH_RECORDS,
    payload
  };
};
