import React from 'react';
import PropTypes from 'prop-types';

// third-party components
import Skeleton from 'react-loading-skeleton';

// constants
import { LANG } from 'constants/lang';
import { SOUNDCLOUD } from 'constants/soundcloud';

// theme
import styles from './SoundcloudSong.module.scss';

const SoundcloudSong = (
    {
      id, // 33425880
      colorHex, // ff5500
      autoplay, // false
      hideRelated, // false
      showComments, // true
      showUser, // true
      showReposts, // false
      showTeaser, // true
      meta
    }) => {

  const {
    soundcloudArtist, // trakan
    // soundcloudArtistTitle, // Trakan
    soundcloudSong, // the-bridge
    soundcloudSongTitle, // Trakan - The Bridge
  } = meta;

  const { listen, to } = LANG.dictionary;
  const { playerHeight } = SOUNDCLOUD;

  return (
    <div className={styles.soundcloud}>
      <div className={styles.loadingSkeleton}>
        <Skeleton height={playerHeight} />
      </div>

      <iframe
          title={`${listen} ${to} ${soundcloudSongTitle}`}
          width="100%"
          height={playerHeight}
          scrolling="no"
          frameBorder="no"
          allow="autoplay"
          src={
            `https://w.soundcloud.com/player/`
            + `?url=https%3A//api.soundcloud.com/tracks/${id}`
            + `&color=%23${colorHex}`
            + `&auto_play=${autoplay}`
            + `&hide_related=${hideRelated}`
            + `&show_comments=${showComments}`
            + `&show_user=${showUser}`
            + `&show_reposts=${showReposts}`
            + `&show_teaser=${showTeaser}`
          }
      />

      <div className={styles.songmeta}>
        {/*
        <a href={`https://soundcloud.com/${soundcloudArtist}`}
           title={soundcloudArtistTitle}
           target="_blank"
        >
          {soundcloudArtistTitle}
        </a> ·
        */}
        <a href={`https://soundcloud.com/${soundcloudArtist}/${soundcloudSong}`}
           target="_blank"
           rel="noopener noreferrer"
        >
          <h2 className={styles.h5}>{soundcloudSongTitle}</h2>
        </a>
      </div>
    </div>
  );
};

SoundcloudSong.propTypes = {
  id: PropTypes.string.isRequired,
  colorHex: PropTypes.string,
  autoplay: PropTypes.bool,
  hideRelated: PropTypes.bool,
  showComments: PropTypes.bool,
  showUser: PropTypes.bool,
  showReposts: PropTypes.bool,
  showTeaser: PropTypes.bool,
  meta: PropTypes.shape({
    soundcloudArtist: PropTypes.string,
    soundcloudArtistTitle: PropTypes.string,
    soundcloudSong: PropTypes.string,
    soundcloudSongTitle: PropTypes.string
  }).isRequired
};

SoundcloudSong.defaultProps = {
  colorHex: 'ff5500',
  autoplay: false,
  hideRelated: false,
  showComments: true,
  showUser: true,
  showReposts: false,
  showTeaser: true,
};

export default SoundcloudSong;
