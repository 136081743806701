export const SOUNDCLOUD = {
  artist: 'trakan', // soundcloudArtist
  artistTitle: 'Trakan', // soundcloudArtistTitle
  hideRelated: true,
  showComments: true,
  showUser: true,
  showReposts: false,
  showTeaser: false,
  playerHeight: 166,
};
