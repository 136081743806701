import { call, put, takeLatest } from 'redux-saga/effects';

// redux
import * as actions from 'store/app/actions';
import { TYPES } from 'store/app/actionTypes';

// api calls
import { getSongs } from 'api/songsApi';
import { getRecords } from 'api/recordsApi';

function* fetchSongs() {
  try {
    const songs = yield call(getSongs);

    if (songs) {
      yield put(actions.setSongs(songs));
    }
  } catch (error) {
    console.error(error);
    yield put(actions.setSongs([]));
  }
}

function* fetchRecords() {
  try {
    const records = yield call(getRecords);

    if (records) {
      yield put(actions.setRecords(records));
    }
  } catch (error) {
    console.error(error);
    yield put(actions.setSongs([]));
  }
}

function* songsSaga() {
  yield takeLatest(TYPES.FETCH_SONGS, fetchSongs);
}

function* recordsSaga() {
  yield takeLatest(TYPES.FETCH_RECORDS, fetchRecords);
}

export const sagas = [
  songsSaga(),
  recordsSaga(),
];
