/**
 * ACTION TYPES: APP STORE
 */
export const TYPES = {
  SET_LOADING: 'app::SET_IS_LOADING',

  SET_SONGS: 'app::SET_SONGS',
  FETCH_SONGS: 'app::FETCH_SONGS',

  SET_RECORDS: 'app::SET_RECORDS',
  FETCH_RECORDS: 'app::FETCH_RECORDS',
};
