import { SOCIAL, SOCIAL_TYPES } from 'constants/social';

/**
 * @param collection - typically SOCIAL from 'constants/social';
 * @param type - from SOCIAL_TYPES
 * @returns {*[]}
 */
export const filterSocialByType = (type, collection = SOCIAL) => {
  const keys = Object.keys(collection).filter(brand => {
    return collection[brand].type === SOCIAL_TYPES[type];
  });

  return keys.map((key) => {
    return collection[key];
  });
};
