import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from 'store/app/actions';

// components
import Record from 'components/_library/_atoms/Record/Record';

// constants
import { RECORD_STORE } from 'constants/recordStore';
import { APP } from 'constants/redux';
import { RECORDS } from 'store/app/constants';
import { LANG } from 'constants/lang';

// theme
import styles from './RecordStore.module.scss';

const RecordStore = () => {
  const dispatch = useDispatch();
  const records = useSelector(state => state[APP][RECORDS]);

  const { SERVICE } = RECORD_STORE;

  const {buy, on} = LANG.dictionary;

  // on component mount
  useEffect(() => {
    // get the test and store them in redux
    dispatch(actions.fetchRecords());

    // on component unmount
    return () => {};
  }, [dispatch]);

  const Records = (
    records.map(({ title, link, img }, i) => (
      <Record key={i}
              title={title}
              link={link}
              img={img}
              service={SERVICE}
      />
    ))
  );

  return (
    <article id="records" className={styles.records}  aria-label={LANG.record.aria}>
      <h3 className={`${styles.h4} ${styles.header}`}>{`${buy} ${on} ${SERVICE}`}</h3>
      <div className={styles.items}>
        {records && Records}
      </div>
    </article>
  );
};

export default RecordStore;
