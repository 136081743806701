import React from 'react';

// router
import { BrowserRouter as Router } from 'react-router-dom';

// components
import { Header } from 'components/_template/Header/Header';
import { Main } from 'components/_template/Main/Main';
import { Footer } from 'components/_template/Footer/Footer';

// theme
import styles from './App.module.scss';

const App = () => (
    <Router>
      <div className={styles.ContainerFluid}>
        <Header/>
        <div className={styles.ContentWrapper}>
          <Main/>
        </div>
        <Footer/>
      </div>
    </Router>
);

export default App;
