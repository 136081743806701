import {
  createStore,
  applyMiddleware
} from 'redux';

import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';

import rootReducer from 'store/rootReducer';
import rootSaga from 'store/rootSaga';

const initialState = {};


// Middleware
const sagaMiddleware = createSagaMiddleware();
const middleware = [
  sagaMiddleware
];
const middlewareEnhancer = applyMiddleware(...middleware);

// Enhancers
const enhancers = [
  middlewareEnhancer
];
const composedEnhancers = composeWithDevTools(...enhancers);


// Build the store
const store = createStore(
  rootReducer,
  initialState,
  composedEnhancers
);

// Run the Sagas
sagaMiddleware.run(rootSaga);

// Export the final redux store
export default store;