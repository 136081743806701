import React from 'react';

// constants
import { LANG } from 'constants/lang';

// theme
import styles from './News.module.scss';

const News = () => {
  const { text, aria } = LANG.news;

  // replace [YEAR] with current year in case news moves slowly, like in the past!
  const Text = (
    text.map((newsItem, i) => (
        <p key={i}>{newsItem.replace('[YEAR]', `${new Date().getFullYear()}`)}</p>
    ))
  );

  return (
    <article id="news-text"  aria-label={aria}>
      <div className={styles.news}>
        {text.length > 0 && Text}
      </div>
    </article>
  );
};

export default News;
