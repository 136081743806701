import React from 'react';

// components
import Bio from 'components/_library/Bio/Bio';
import News from 'components/_library/News/News';
import Contact from 'components/_library/Contact/Contact';
import Songs from 'components/_library/Songs/Songs';
import RecordStore from 'components/_library/RecordStore/RecordStore';
import SocialIcons from 'components/_library/SocialIcons/SocialIcons';

// constants
import { COLORS, SIZES } from 'constants/theme';

// theme
import styles from './Home.module.scss';
import { SOCIAL_TYPES } from "../../../constants/social";

export const Home = () => (
  <div className={styles.homePage}>
    <div className={styles.left}>
      <div className={styles.hero} />
      <section id="contact">
        <Contact />
      </section>
      <section id="social">
        <SocialIcons
            type={SOCIAL_TYPES.APP}
            size={SIZES.LARGE}
            color={COLORS.LIGHT}
        />
        <SocialIcons
            type={SOCIAL_TYPES.STORE}
            size={SIZES.LARGE}
            color={COLORS.LIGHT}
        />
      </section>
    </div>

    <div className={styles.right}>
      <section id="bio">
        <Bio />
      </section>

      <section id="news">
        <News />
      </section>

      <section id="listen">
        <Songs />
      </section>

      <section id="store">
        <RecordStore />
      </section>
    </div>
  </div>
);
