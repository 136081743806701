import React from 'react';

import { Route, Switch } from 'react-router-dom';
import { Routes } from 'router/routes';

export const Router = () => {
  const routingComponents = Routes.map(({ path, component, exact }, index) => {
    return (
      <Route
        key={index}
        path={path}
        component={component}
        exact={exact}
      />
    );
  });

  return (
    <Switch>
      {routingComponents}
      <Route render={() => <h1>404: page not found</h1>}/>
    </Switch>
  );
};
