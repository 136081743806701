import React from 'react';
import PropTypes from "prop-types";

// components
import SocialIcon from 'components/_library/_atoms/SocialIcon/SocialIcon';

// utils
import { filterSocialByType } from 'utils/social';

// constants
import { SOCIAL_BRANDS } from 'constants/social';
import { COLORS, SIZES } from 'constants/theme';
import { LANG } from 'constants/lang';

// theme
import styles from './SocialIcons.module.scss';


/**
 * @param type = social type: 'STORE' or 'APP'
 * @param color (default dark)
 * @param size (default small)
 * @returns {*}
 * @constructor
 */
const SocialIcons = ({type, color, size}) => {
  const iconData = filterSocialByType(type);

  const Icons = iconData.map((data, i) => {
    return (
      <SocialIcon
        key={i}
        brand={SOCIAL_BRANDS[data.brand]}
        color={color}
        size={size}
      />
    );
  });

  return (
    <article id={`social-${type}`} className={styles.socialIcons}  aria-label={`${LANG.social.aria}: ${type}S`}>
      {Icons}
    </article>
  );
};

SocialIcons.propTypes = {
  type: PropTypes.string.isRequired,
  color: PropTypes.string,
  size: PropTypes.string,
};

SocialIcons.defaultProps = {
  color: COLORS.DARK,
  size: SIZES.SMALL,
};

export default SocialIcons;
